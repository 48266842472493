import CryptoJS from 'crypto-js'

export type User = {
    name:string;
    token:string;
    admin?:boolean;
}

export var user:User;

var loadingUser:Promise<User> | null;

export async function login(username:string, password:string):Promise<User>
{
    let userId = CryptoJS.MD5(username + ":" + password).toString();
    return auth(userId);   
}

export async function getUser():Promise<User>
{
    if (loadingUser != null)
    {
        return loadingUser;
    }
    if (user != null)
    {
        return user;
    }
    else if (typeof window !== "undefined")
    {
        loadingUser = auth(window.localStorage.getItem("userId") || "");
        return loadingUser;
    }
    else
    {
        throw new Error("No user");
    }
}

export function logout()
{
    window.localStorage.removeItem("userId");
    window.location.reload();
}


async function auth(userId:string):Promise<User>
{
    if (!userId || userId.length != 32)
    {
        loadingUser = null;
        window.localStorage.setItem("userId", "");
        throw new Error("Invalid user");
    }
           
    try 
    {
        let result = await fetch("/users/" + userId + ".json?ts=" + (Date.now() - Date.now() % 86400000))
        let resultStr = await result.text();
        let resultObj = JSON.parse(resultStr);

        if (resultObj && resultObj.name && resultObj.token)
        {
            user = resultObj;
            window.localStorage.setItem("userId", userId);
            loadingUser = null;
            return user;
        }
        else
        {
            throw new Error("Invalid user");
        }
    }
    catch (e) 
    {
        loadingUser = null;
        throw new Error("Failed to auth");
    }
}